import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';
import { DefaultProfileImageService } from '@pixels/client/components/photo-select/default-profile-image.service';
import { type InstaChatUserForUserInfo } from '@pixels/universal/model/insta-chat/insta-chat-user/insta-chat-user.schema';
import { IdolMember } from '@pixels/universal/model/kpop/idol-member/idol-member.schema';
import { map, Observable } from 'rxjs';
import { Cacheable } from 'ts-cacheable';

@Injectable({ providedIn: 'root' })
export class KpopDefaultProfileImageService extends DefaultProfileImageService {
  private readonly httpClient = inject(HttpClient);

  public override get text(): string {
    return this.t.instant(_('@다른_멤버_사진으로_변경@'));
  }

  public override emptyProfileImageUrls(instaChatUser: Partial<InstaChatUserForUserInfo>): Observable<string[]> {
    if (instaChatUser.artist) {
      return this.fetchKpopIdolMembers(instaChatUser.artist).pipe(
        map(idolMembers => idolMembers.map(idolMember => idolMember.imageUrl as string)),
      );
    }
    return super.emptyProfileImageUrls(instaChatUser);
  }

  @Cacheable()
  private fetchKpopIdolMembers(groupName: string): Observable<IdolMember[]> {
    return this.httpClient.get<IdolMember[]>(`/idol-members/by-group/${groupName}`);
  }
}
