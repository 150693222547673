import { inject, Injectable, signal } from '@angular/core';
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';
import { Platform } from '@ionic/angular/standalone';
import { AppInstallType } from '@pixels/client/components/app-banner/app-banner.model';
import { PHASE_TOKEN } from '@pixels/client/injection-tokens/environment-token';
import { PATH_MAP } from '@pixels/client/one-on-one/core/constant';
import { KPOP_ONLY_ADMIN_MENUS, ONE_ON_ONE_ADMIN_MENUS } from '@pixels/client/pages/admin/admin.model';
import { AppInfo } from '@pixels/client/pixels/app-info';
import { AppKeyKpop } from '@pixels/universal/model/apps/app-types';
import { KpopChatProjectMeta } from '@pixels/universal/model/apps/project-meta/kpop-chat-project-meta';
import { Phase } from '@pixels/universal/model/phase-model';

@Injectable({ providedIn: 'root' })
export class KpopAppInfo extends AppInfo {
  public readonly appType = AppKeyKpop;
  public readonly serviceName = _('@KPOP_CHAT@');
  public readonly appStoreUrl = signal(KpopChatProjectMeta.appStoreUrl ?? '');
  public readonly playStoreUrl = signal(`https://play.google.com/store/apps/details?id=${KpopChatProjectMeta.appId}`);

  public override readonly unauthenticatedRedirectUrl = PATH_MAP.chatRooms.fullPath;
  public override readonly enabledBoardPostsDefaultNation: boolean = false;
  public override readonly enabledOpenChatsPostDefaultNation: boolean = false;
  public override readonly enabledWaitingRoomsDefaultNation: boolean = false;
  public override readonly admobBannerListAppId = (inject(Platform).is('ios') ? KpopChatProjectMeta.ios?.admobBannerListAppId : KpopChatProjectMeta.android?.admobBannerListAppId) ?? '';
  public override readonly needContentAgreement: boolean = true;
  public override readonly enabledWaitingRoomInitGenderFilter: boolean = false;
  public override readonly adminMenus = [...ONE_ON_ONE_ADMIN_MENUS, ...KPOP_ONLY_ADMIN_MENUS];
  public override readonly loginTitle = _('@KPOPchat@');
  public override readonly loginDescription = _('@KPOP_팬들_모여_n_친구를_만들어보아요@');
  public override readonly loginAppIconUrl = 'https://cdn.pixelteam.io/pixels/app-icons/kpop-app-icon.svg';
  public override readonly bannerPlaceHolder = _('@KPOP_CHAT_팬채팅_나와_닮은_아이돌_찾기@');
  public override readonly enableAppBanner = true;
  public override readonly appInstallType = AppInstallType.v2;
  public override readonly appInstallImageUrl = 'https://cdn.pixelteam.io/kpop/assets/kpop-chat/app-banner/app-banner.png';
  public override readonly appInstallTitle = _('@KPOP_Chat_앱_다운받고_애정하는_아티스트_덕질하기@');
  public override readonly showShareButton = true;
  private readonly phase = inject(PHASE_TOKEN);
  public override readonly shareUrl = this.phase === Phase.prod ? 'https://kpop.pixels.im' : 'https://dev-kpop.pixels.im';
  public override readonly contentBaseUrl = this.phase === Phase.prod ? 'https://kpop.pixels.im' : 'https://dev-kpop.pixels.im';
}
