import { AppKeyKpop } from '@pixels/universal/model/apps/app-types';
import { ProjectMeta } from '@pixels/universal/model/apps/project-meta.model';
import { Phase } from '@pixels/universal/model/phase-model';

const FOLDER_NAME = 'kpop-chat-client';
const PROD_WEB_PUSH_KEY = 'BO6OelItmyTMSo5_38tNzJLrDe1SO53zOIDa0I_yuR5RS0Uf-F6RFDLfexG5JhezaLvq-VeghglxA6QRB6W6PY4';
const DEV_WEB_PUSH_KEY = 'BGoGgj_aLCUltH8buevZ_9mlqT0GPL9nmwVdnGLQ2uSzi3qX6gbi6y9nQd5aS0y6Gn6RU2OwOI9RRsEqRI_lQgQ';
const ProdFirebaseConfig = {
  apiKey: 'AIzaSyAc2kJXHESLEafYYaU-TsTk01kWAXpt4K0',
  authDomain: 'pixels-kpop.firebaseapp.com',
  projectId: 'pixels-kpop',
  storageBucket: 'pixels-kpop.appspot.com',
  messagingSenderId: '191256227751',
  appId: '1:191256227751:web:ca488171e85ecb5f7f1082',
  measurementId: 'G-X3NQ8K6P2T'
};
const DevFirebaseConfig = {
  apiKey: 'AIzaSyBgS2UNmihEEhjhNSDpBbGfa3zwEkE87YA',
  authDomain: 'pixels-kpop-dev.firebaseapp.com',
  projectId: 'pixels-kpop-dev',
  storageBucket: 'pixels-kpop-dev.appspot.com',
  messagingSenderId: '914047248036',
  appId: '1:914047248036:web:dde94026f3bfd23d3e6831',
  measurementId: 'G-0Z001M14JC'
};
const PROD_TALK_PLUS_APP_ID = 'd0a50ddc-a5f1-47b7-96c9-74a6c4c4edd5';
const DEV_TALK_PLUS_APP_ID = 'bf063f7c-9102-4d51-9426-7f91d315aec1';

export const KpopChatProjectMeta: ProjectMeta = {
  id: AppKeyKpop,
  mainPrimaryColor: '#000000',
  folderName: FOLDER_NAME,
  publicPath: `dist/apps/${FOLDER_NAME}/browser`,
  supportPhases: [Phase.prod, Phase.dev],
  port: 8104,
  apiName: 'kpop-api',
  apiPort: 5004,
  appId: 'io.pixelteam.kpop',
  appStoreUrl: 'https://apps.apple.com/app/id6469809919',
  appName: 'KPOP CHAT',
  appNameEn: 'KPOP CHAT',
  appSchemeName: 'KPOP CHAT',
  testTargetUrl: 'https://test-kpop.pixelteam.io',
  devTargetUrl: 'https://dev-kpop.pixelteam.io',
  prodApi: 'https://kpop-api.pixelteam.io',
  devApi: 'https://dev-kpop-api.pixelteam.io',
  firebaseId: 'pixels-kpop',
  testHostname: 'test-kpop',
  clientSentryDsn: 'https://0b77c7bc8064d31e3aaa5bc51f8dc53a@o190729.ingest.us.sentry.io/4506061803945984',
  prod: {
    deployPath: '/api/v1/project/9070/stage/10519/scenario/11805/deploy',
    targets: ['prod-02', 'prod-03'],
    port: 8000,
    androidAppId: '1:191256227751:android:60d0385e5849d7317f1082',
    iosAppId: '1:191256227751:ios:b16daf883029033a7f1082',
    iosEncodingAppId: 'app-1-191256227751-ios-b16daf883029033a7f1082',
    webAppId: '1:191256227751:web:ca488171e85ecb5f7f1082',
    kakaoAppKey: '0ac0a35a61d39fef9ab66b0e883edf1c',
    googleReversedId: 'com.googleusercontent.apps.191256227751-rpg3jo0v4e6c5pr7epadossjeft7b2b6',
    webPushKey: PROD_WEB_PUSH_KEY,
    firebaseConfig: ProdFirebaseConfig,
    talkPlusAppId: PROD_TALK_PLUS_APP_ID,
    apiUrl: 'https://kpop-api.pixelteam.io',
  },
  dev: {
    deployPath: '/api/v1/project/9070/stage/10104/scenario/11286/deploy',
    targets: ['dev-01'],
    port: 8001,
    androidAppId: '1:914047248036:android:4987b15baa6d676c3e6831',
    iosAppId: '1:914047248036:ios:39f21398b2af38273e6831',
    iosEncodingAppId: 'app-1-914047248036-ios-39f21398b2af38273e6831',
    webAppId: '1:914047248036:web:dde94026f3bfd23d3e6831',
    kakaoAppKey: 'bb985a9973537329fd7f35092f979eb7',
    googleReversedId: 'com.googleusercontent.apps.914047248036-ti8jog8andg5ha5h6ev0trr4k93nb19s',
    webPushKey: DEV_WEB_PUSH_KEY,
    firebaseConfig: DevFirebaseConfig,
    talkPlusAppId: DEV_TALK_PLUS_APP_ID,
    apiUrl: 'https://dev-kpop-api.pixelteam.io',
  },
  test: {
    androidAppId: '1:914047248036:android:80edf7a74e93f8443e6831',
    iosAppId: '1:914047248036:ios:1ddd6a96c54fb4223e6831',
    iosEncodingAppId: 'app-1-914047248036-ios-1ddd6a96c54fb4223e6831',
    kakaoAppKey: 'bb985a9973537329fd7f35092f979eb7',
    googleReversedId: 'com.googleusercontent.apps.914047248036-qmanjo8j1p3sncri3gurga6obovpfd0u',
    webPushKey: DEV_WEB_PUSH_KEY,
    firebaseConfig: DevFirebaseConfig,
    talkPlusAppId: DEV_TALK_PLUS_APP_ID,
    apiUrl: 'https://test-kpop-api.pixelteam.io',
  },
  android: {
    admobAppId: 'ca-app-pub-4000507444081320~1790053146',
    admobBannerListAppId: 'ca-app-pub-4000507444081320/5836907071',
    admobBannerChatRoomAppId: 'ca-app-pub-4000507444081320/2552268185',
    admobBannerVideoChatAppId: 'ca-app-pub-4000507444081320/2685532497',
  },
  ios: {
    admobAppId: 'ca-app-pub-4000507444081320~6088390807',
    admobBannerListAppId: 'ca-app-pub-4000507444081320/6351912396',
    admobBannerChatRoomAppId: 'ca-app-pub-4000507444081320/2569214516',
    admobBannerVideoChatAppId: 'ca-app-pub-4000507444081320/4210367154',
  },
  NSCameraUsageDescription: '카메라로 찍은 사진을 메시지에 첨부 또는 프로필 사진으로 등록할 수 있습니다.',
  NSPhotoLibraryAddUsageDescription: '회원님이 KPOP CHAT에 사진을 저장에 대한 액세스 권한을 사용합니다.',
  NSPhotoLibraryUsageDescription: '앨범에 있는 사진과 GIF를 메시지에 첨부 또는 프로필 사진으로 등록할 수 있습니다.',
  NSCameraUsageDescriptionEn: 'You can attach photos taken with your camera to messages or register them as your profile picture',
  NSPhotoLibraryAddUsageDescriptionEn: 'The member uses access to save photos to KPOP CHAT',
  NSPhotoLibraryUsageDescriptionEn: 'You can attach photos and GIFs from your albums to messages or as profile pictures',
  consoleLogo: `
██╗  ██╗██████╗  ██████╗ ██████╗      ██████╗██╗  ██╗ █████╗ ████████╗
██║ ██╔╝██╔══██╗██╔═══██╗██╔══██╗    ██╔════╝██║  ██║██╔══██╗╚══██╔══╝
█████╔╝ ██████╔╝██║   ██║██████╔╝    ██║     ███████║███████║   ██║   
██╔═██╗ ██╔═══╝ ██║   ██║██╔═══╝     ██║     ██╔══██║██╔══██║   ██║   
██║  ██╗██║     ╚██████╔╝██║         ╚██████╗██║  ██║██║  ██║   ██║   
╚═╝  ╚═╝╚═╝      ╚═════╝ ╚═╝          ╚═════╝╚═╝  ╚═╝╚═╝  ╚═╝   ╚═╝   
`
};
